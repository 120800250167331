<script setup>
import {price} from '@/Helpers/formatters';
import {Link, useForm, usePage} from "@inertiajs/vue3";
import InputLabel from "@/Components/Shared/Forms/InputLabel.vue";
import {onMounted, ref, watch} from "vue";
import debounce from "lodash/debounce";
import TextInput from "@/Components/Shared/Forms/TextInput.vue";
import InputError from "@/Components/Shared/Forms/InputError.vue";
import Card from "@/Components/Shared/Cards/Card.vue";
import CardLink from "@/Components/Shared/Cards/CardLink.vue";
import CartItem from "@/Components/Shared/Restaurant/CartItem.vue";
import {useCheckoutDetails} from "@/Composables/checkoutDetails";
import route from '@/Helpers/route';

const {
    zipcode, saveDetails, loadDetails
} = useCheckoutDetails();

const page = usePage();

const props = defineProps({
    cart: Object,
    isAtCheckout: {
        type: Boolean,
        default: false
    }
});

const form = useForm({
    zipcode: props.cart.zipcode
});

const saveZipcode = () => form.post(route(`${page.props.routing_group}.cart.zipcode`, {
    [page.props.routing_key]: page.props.routing_target
}), {
    preserveState: true,
    preserveScroll: true,
    only: ['cart'],
});

onMounted(() => {
    watch(() => form.zipcode, debounce(() => {
        zipcode.value = form.zipcode;
        saveDetails();
        saveZipcode();
    }, 750));

    loadDetails();
    form.zipcode = zipcode.value;
    saveZipcode();
});

const emit = defineEmits(['toggle-cart']);
const toggleCart = () => emit('toggle-cart');

const isLoadingTransportation = ref(null);

const itemsList = ref();
const showItemsShadow = ref(false);
onMounted(() => watch(() => props.cart.items.length, () => showItemsShadow.value = itemsList.value.scrollHeight > itemsList.value.clientHeight, { deep: true }));
</script>

<template>
    <Card as="aside" dusk="cart">
        <div class="flex justify-between p-3">
            <p class="font-bold text-2xl text-gray-700">Mijn winkelmand</p>

            <button class="xl:hidden flex w-8 h-8 justify-center items-center bg-white hover:bg-gray-100 aspect-square rounded-lg my-auto" @click="toggleCart" dusk="close">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="w-[24px] h-[24px] fill-gray-700">
                    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                </svg>
            </button>
        </div>

        <ul
            class="divide-y border-t max-h-[30vh] xl:max-h-[60vh] overflow-auto"
            :class="{ 'shadow-[inset_0_-5px_5px_-5px_rgba(0,0,0,0.1),inset_0_5px_5px_-5px_rgba(0,0,0,0.1)]': showItemsShadow }"
            ref="itemsList">
            <template v-if="cart.items.length > 0">
                <CartItem v-for="item in cart.items" :key="item.id" :item="item" />
            </template>
            <template v-else>
                <li class="text-orange-500 text-center font-medium p-3" dusk="empty-cart">
                    Je winkelmand is leeg
                </li>
            </template>
        </ul>

        <ul class="divide-y border-t">
            <li class="flex flex-row justify-between p-3">
                <p class="font-medium">Subtotaal</p>
                <p>{{ price(cart.subtotal) }}</p>
            </li>

            <template v-if="! isAtCheckout">
                <li class="flex flex-col p-3">
                    <p class="font-medium">Bezorgmethode:</p>

                    <div class="grid grid-cols-2 gap-2">
                        <Link :href="route(`${page.props.routing_group}.cart.transportation`, { [page.props.routing_key]: page.props.routing_target })"
                              method="post" as="button" type="button" :data="{ transportation: 'delivery' }"
                              :only="['cart']" class="flex flex-row gap-2 justify-center items-center bg-blue-500 text-white px-2 py-2 rounded text-sm" :class="{ 'font-bold': cart.transportation === 'delivery' }"
                              dusk="delivery" v-if="cart.can_deliver_today" :onBefore="() => isLoadingTransportation = 'delivery'" :onFinish="() => isLoadingTransportation = null"
                              preserve-scroll>
                            Bezorgen

                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fill-white animate-spin" v-if="isLoadingTransportation === 'delivery'">
                                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                            </svg>
                        </Link>

                        <Link :href="route(`${page.props.routing_group}.cart.transportation`, { [page.props.routing_key]: page.props.routing_target })"
                              method="post" as="button" type="button" :data="{ transportation: 'pickup' }"
                              :only="['cart']" class="flex flex-row gap-2 justify-center items-center bg-blue-500 text-white px-2 py-2 rounded text-sm" :class="{ 'font-bold': cart.transportation === 'pickup' }"
                              dusk="pickup" v-if="cart.can_pickup_today" :onBefore="() => isLoadingTransportation = 'pickup'" :onFinish="() => isLoadingTransportation = null"
                              preserve-scroll>
                            Afhalen

                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fill-white animate-spin" v-if="isLoadingTransportation === 'pickup'">
                                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                            </svg>
                        </Link>
                    </div>

                    <p class="text-red-500 font-medium" v-if="! cart.can_deliver_today && ! cart.can_pickup_today">Helaas zijn wij nu gesloten</p>
                </li>

                <li class="p-3" v-if="cart.transportation === 'delivery'">
                    <div class="mb-2">
                        <InputLabel for="zipcode" value="Postcode" />
                        <div class="flex flex-row gap-2 items-center">
                            <TextInput id="zipcode" type="text" class="block w-full" v-model="form.zipcode" @blur="saveZipcode" dusk="zipcode" required />

                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="w-6 h-6 fill-blue-500 animate-spin" v-if="form.processing">
                                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                            </svg>
                        </div>
                        <InputError class="mt-2" :message="form.errors.zipcode" />
                    </div>

                    <p class="text-red-500 font-medium text-sm" v-if="cart.transportation === 'delivery' && cart.zipcode && !cart.delivery.delivers_to" dusk="out-of-range-message">
                        Helaas bezorgen wij niet naar {{ cart.zipcode }}
                    </p>

                    <div class="flex flex-row justify-between" v-if="cart.minimum.left > 0">
                        <p class="text-orange-500 font-medium text-sm">Minimum bestel bedrag</p>
                        <p class="text-orange-500 text-sm" dusk="minimum-order-price">{{ price(cart.minimum.price) }}</p>
                    </div>
                </li>
            </template>

            <li class="p-3" v-if="cart.transportation === 'delivery'">
                <div class="flex flex-row justify-between">
                    <p class="font-medium">Bezorgkosten</p>
                    <p>{{ price(cart.delivery.price) }}</p>
                </div>

                <p class="text-green-500 font-medium text-sm" v-if="cart.transportation === 'delivery' && cart.delivery.is_free" dusk="cart_delivery_is_free">
                    Je eten wordt gratis bezorgd!
                </p>

                <p class="text-green-500 font-medium text-sm" v-if="cart.transportation === 'delivery' && cart.delivery.left > 0 && cart.delivery.delivers_to && cart.minimum.is_over" dusk="free-delivery-above-message">
                    Bestel voor nog {{ price(cart.delivery.left) }} en je eten wordt gratis bezorgd.
                </p>
            </li>

            <li class="pb-3">
                <div class="flex flex-row justify-between pt-3 px-3">
                    <p class="font-medium">Totaal</p>
                    <p>{{ price(cart.total) }}</p>
                </div>
                <div class="flex flex-row justify-between text-gray-700 px-3" v-if="false">
                    <p class="font-medium text-xs">btw</p>
                    <p class="text-xs">{{ price(cart.vat) }}</p>
                </div>
                <div class="flex flex-row justify-between text-gray-700 px-3" v-if="false || cart.deposit > 0">
                    <p class="font-medium text-xs">statiegeld</p>
                    <p class="text-xs">{{ price(cart.deposit) }}</p>
                </div>
            </li>

            <li v-if="! isAtCheckout">
                <CardLink :href="route(`${page.props.routing_group}.checkout`, { [page.props.routing_key]: page.props.routing_target })" variant="green" :disabled="! cart.can_checkout" dusk="go-to-checkout">
                    Afrekenen
                </CardLink>
            </li>

            <li v-if="isAtCheckout">
                <CardLink :href="route(`${page.props.routing_group}.menu`, { [page.props.routing_key]: page.props.routing_target })" variant="blue" :disabled="! cart.can_checkout" dusk="back-to-menu">
                    Terug naar menukaart
                </CardLink>
            </li>

        </ul>
    </Card>
</template>
